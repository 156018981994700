<template>
  <b-container
    fluid="xl"
  >
    <div class="d-flex pt-4 pb-4">
      <div
        class="h3 mb-0 mr-auto"
      >
        Тэги
      </div>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="ml-2"
        size="sm"
        @click="openNewTagModal"
      >
        Новый тэг
      </b-button>
    </div>

    <div class="d-flex mb-3">
      <b-form-input
        v-model="query"
        type="text"
        placeholder="Введите название или описание"
        debounce="300"
        @keyup.native.enter="tagsFetch"
      />

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="ml-2"
        @click="tagsFetch"
      >
        <b-icon icon="search" />
      </b-button>
    </div>

    <div
      v-if="!totalCount && !isLoading"
    >
      Ничего не найдено
    </div>

    <template v-else>
      <div class="queues-info-wrapper pr-1">
        <div
          v-if="isLoading"
          class="d-flex justify-content-center mt-5"
        >
          <b-spinner
            label="Loading..."
            variant="primary"
            style="width: 5rem; height: 5rem;"
          />
        </div>

        <queues-tag-info
          v-for="(tag) in filteredTags"
          :key="tag.id"
          :tag="tag"
          class="mb-3"
        />
      </div>
    </template>
  </b-container>
</template>

<script>
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';
import QueuesTagInfo from '@/components/Queues/QueuesTagInfo';

export default {
  name: 'QueuesTags',
  page: {
    title: 'CRM Doctis - Тэги',
  },
  components: {
    QueuesTagInfo,
  },
  mixins: [mixinRoles],
  data() {
    return {
      query: '',
      isLoading: false,
    };
  },
  computed: {
    tags() {
      return this.$store.state.Queues.tags;
    },
    filteredTags() {
      if (!this.query) return this.tags;

      return this.tags.filter((tag) => {
        const query = this.query?.toLowerCase();
        const name = tag.name?.toLowerCase();
        const comment = tag.comment?.toLowerCase();

        return comment?.includes(query) || name?.includes(query);
      });
    },
    totalCount() {
      return this.filteredTags.length;
    },
  },
  async created() {
    await this.tagsFetch();

    Bus.$on('queues-tags:fetch', this.tagsFetch);
  },
  beforeDestroy() {
    Bus.$off('queues-tags:fetch', this.tagsFetch);
  },
  methods: {
    openNewTagModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'QueuesTagEditModal',
        props: {
          // tag: this.tag,
          afterSave: () => this.tagsFetch(),
        },
      });
    },
    async tagsFetch() {
      if (this.isLoading) return;

      this.isLoading = true;
      try {
        await this.$store.dispatch(this.$types.QUEUES_TAGS_FETCH, { save: true });
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.queues-info-wrapper {
  height: calc(100vh - 150px);
  overflow: auto;
}

</style>

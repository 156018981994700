<template>
  <div class="d-flex bg-white py-3 px-4 border rounded">
    <div
      class="mr-2"
      style="width: 200px;"
    >
      <div
        class="text-secondary mb-1"
        style="font-size: 0.8rem"
      >
        Название
      </div>
      <div class="font-weight-bold">
        {{ tag.name || 'Без названия' }}
      </div>
    </div>

    <div
      class="mr-4"
      style="width: 300px;"
    >
      <div
        class="text-secondary mb-1"
        style="font-size: 0.8rem"
      >
        Комментарий
      </div>
      <div>
        {{ tag.comment || 'Без комментарий' }}
      </div>
    </div>

    <div class="d-flex flex-column ml-auto">
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        @click="openEditModal"
      >
        Редактировать
      </b-button>

      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="mt-2"
        size="sm"
        @click="openDeleteModal"
      >
        Удалить
      </b-button>
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';

export default {
  name: 'QueuesTagInfo',
  props: {
    tag: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    openEditModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'QueuesTagEditModal',
        props: {
          tag: this.tag,
          afterSave: () => Bus.$emit('queues-tags:fetch'),
        },
      });
    },
    openDeleteModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'QueuesTagDeleteModal',
        props: {
          tagId: this.tag.id,
          afterDelete: () => Bus.$emit('queues-tags:fetch'),
        },
      });
    },
  },
};
</script>
